html{
    scroll-behavior: smooth;
}

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #181717;
    color: white;
    scroll-behavior: smooth;
}

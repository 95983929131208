@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

.Navigation{
    width: 100%;
    height: auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

.navigation_header{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    z-index: 3;
    background: rgb(19,19,19);
    background: linear-gradient(45deg, rgba(19,19,19,1) 6%, rgba(143,158,68,1) 45%, rgba(193,39,39,1) 100%);
    width: 100%;
    height: 2rem;
}

.navigation_header > div {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    color:#58595b;
}

.navigation_header > div > a {
    color: #58595b;
    text-decoration: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

nav {
    z-index: 4;
    position: absolute;
    width: 60%;
    font-size: 1.5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    display: flex;
    top: 4rem;
    left: 50%;
    transform: translate(-50%, 0);
    font-weight: 500;
}

nav > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.underline {
    width: 0;
    height: 0.15rem;
    background-color: white;
    transition: width 0.2s;
}

div:hover > .underline {
    width: 100%;
}

nav > div > a {
    color: white;
    text-decoration: none;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.logoAndMotto {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 100;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 22rem;
}
.motto {
    color: white;
    font-size: 2rem;
    text-align: center;
}

@media (max-width: 850px) {
    .logo {
        width: 15rem;
    }
    .motto {
        font-size: 1rem;
    }

    nav {
        width: 70%;
        font-size: 1rem;
    }
}
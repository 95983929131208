.Contact{
    width: 100%;
    padding: 2rem 0 0 0 ;
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-behavior: smooth;
}

.ContactSections{
    width: 80%;
    font-size: larger;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.ContactFirstSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.ContactSecondSection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

iframe{
    padding: 1rem 0 ;
    width: 100%;
    height: 30rem;
}

.footer{
    color: black;
    font-size: large;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media (max-width: 850px) {
    .ContactSections{
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .ContactSecondSection{
        padding-top: 2.2rem;
    }
}
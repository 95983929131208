.Events {
    width: 80%;
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: larger;
    display: flex;
    flex-direction: column;
    align-items: center;
}

header{
    font-size: xx-large;
    color: black;
}
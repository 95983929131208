.Offer {
    width: 80%;
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: larger;
    scroll-behavior: smooth;
}

.offerSection{
    box-shadow: 0 0.4rem 2rem rgb(0, 0, 0);
    border-radius: 0.5rem;
    padding: 2rem;
    transition: box-shadow 1s;
}

.offerSection:hover {
    box-shadow: 0 0.4rem 2rem rgb(99, 98, 98);
}

.offerText {
    font-weight: 500;
    font-size: large;
}

.offerImages{
    padding-top: 1rem;
}

.offerImage{
    height:20rem;
    padding: 0 1rem;
}

.offerImageEngine{
    width: 18rem;
    padding: 0 1rem;
}

.offerContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.mail{
    color: white;
}

.shop {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Offer .ulNav {
    width: 100%;
    height: auto;
    margin: 0;
    list-style-type: none;
    padding: 10px 0;
    margin: 10px 0;
    display: flex;

}

.Offer a {
    text-decoration: none;
    color: white;
    padding: 0;
    margin: 0;
}

li::before{
    content: "\00BB \00a0\00a0";
}

.mailbox {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 850px) {
    
    .offerContent{
        flex-direction: column;
        justify-content: space-around;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .offerImages{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .offerImage{
        height: 15rem;
        padding-top: 1rem;
    }
    .offerText{
        padding-top: 1rem;
    }
    .offerImageEngine{
        width: 14rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}